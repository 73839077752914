<template>
  <div style="margin-left: 20px;margin-bottom: 20px">
    <div v-if="questionInfo.type != 1" class="brief">
      <template v-if="questionInfo.type == 3">
        <div>参考答案：</div>
        <div style="flex: 1">
          <p v-for="(item, index) in questionInfo.children || []" :key="index">
            {{ index + 1 }} 、
            {{
              (item.answer || [])
                .map((d) => {
                  return d.answer === undefined ? d : d.answer;
                })
                .join("、")
            }}
          </p>
        </div>
      </template>
      <template v-else>
        <div>参考答案：</div>
        <div style="flex: 1">
          <p>
            {{
              (questionInfo.answer || [])
                .map((d) => {
                  return d.answer === undefined ? d : d.answer;
                })
                .join("、")
            }}
          </p>
        </div>
      </template>
    </div>
    <el-divider v-if="questionInfo.type != 1"></el-divider>
    <div class="brief">
      <div>答案解析：</div>
      <div style="flex: 1" v-if="questionInfo.analysis">
        <span
          v-code-html="questionInfo.analysis"
        ></span>
      </div>
      <span v-else style="color: #979797">暂无解析</span>
    </div>
  </div>
</template>

<script>
import { detail } from "@/api/topic.js"
export default {
  props: {
    // questionInfo: {
    //   type: Object,
    //   default: () => {
    //     return {};
    //   },
    // },
    topicId: {
      type: String,
      default() {
        return "0";
      },
    },
  },
  watch: {
    topicId() {
      this.getDetail();
    },
  },
  data() {
    return {
      questionInfo:{},
      details: {},

    };
  },
  created() {
    this.getDetail();
  },
  methods: {
    getDetail() {
      // return
      detail({ id: this.topicId })
        .then(({data}) => {
          // console.log(res, "resresres");
          data.question.description = data.question.description.replace(/<img/g, "<img style='max-width:100%;height:auto;'")
          this.details = {...data}
          this.questionInfo = {...(data.question||{}),options:data.options,children:data.children || []}

          // let questionInfo = (res.data || {}).question || {};
          // let children = (res.data || {}).children || [];
          // let options = (res.data || {}).options || [];
          // this.questionInfo = { ...questionInfo, options };
          console.log(this.questionInfo ,"this.question this.question ")
          // this.detailInfo = { ...res.data, children, questionInfo };
        })
        .finally(() => {});
    },
  },
};
</script>

<style scoped>
.brief {
  font-size: 14px;
  line-height: 26px;
  display: flex;
}
</style>
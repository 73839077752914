<template>
  <el-card
    class="evaluate"
    style="border: none; box-shadow: none"
    v-loading="loading"
    :body-style="{ padding: '0px', marginBottom: '20px' }"
  >
    <div v-if="!(dataList.list || []).length" class="default">
      <slot name="empty">
        <div
          style="display: flex; justify-content: center; align-items: center"
        >
          暂无
        </div>
        <!-- <el-empty :image="require('@/assets/images/course/none.png')" description="暂无评价"></el-empty> -->
      </slot>
    </div>
    <div class="evaluate-item" v-for="(v, i) in dataList.list" :key="i">
      <div class="user">
        <el-avatar
          size="large"
          :src="v.user_headimg || require('@/assets/index/tx.png')"
        ></el-avatar>
        <div class="user-name">{{ v.user_nickname }}</div>
      </div>
      <div class="text">
        <div>{{ v.content }}</div>
        <div class="time">
          <span>{{ v._create_time }}</span>
          <span class="reply" v-if="userInfo.id + '' !== v.user_id + ''">
            <el-popover
              :ref="'popover' + v.id"
              placement="top-end"
              width="500"
              trigger="click"
            >
              <div class="ipt">
                <el-input
                  v-model="replyForm.content"
                  placeholder="请输入内容"
                  type="textarea"
                  :rows="3"
                ></el-input>
              </div>

              <div style="text-align: right; margin-top: 20px">
                <el-button
                  type="primary"
                  size="mini"
                  @click="handleCommand('reply', v.id)"
                  >确定</el-button
                >
              </div>
              <div slot="reference">
                <el-button type="text" style="color: var(--color-primary)"
                  >回复</el-button
                >
              </div>
            </el-popover>
          </span>
        </div>
      </div>
    </div>
    <div class="pagination" style="text-align: center; padding-bottom: 20px">
      <el-pagination
        background
        layout="prev, pager, next"
        :total="dataList.total"
        @current-change="getList((dataList.page = $event))"
        :page-size="dataList.pageSize"
        :current-page="dataList.page"
        :hide-on-single-page="true"
      >
      </el-pagination>
    </div>
    <div style="padding: 0 8%" v-if="isComment !== false">
      <el-input
        placeholder="我来说一句"
        v-model="publishForm.content"
        size="small"
      >
        <div
          style="
            background: #00957e;
            width: 80px;
            color: #fff;
            text-align: center;
            line-height: 32px;
            border-radius: 0 4px 4px 0;
            cursor: pointer;
          "
          slot="suffix"
          type="text"
          @click="handleCommand('publish')"
        >
          发表
        </div>
      </el-input>
    </div>
  </el-card>
</template>
  
  <script>
export default {
  props: {
    type: {
      type: String,
      default() {
        return "course";
      },
      validator(value) {
        return ["course", "question", "forum"].includes(value);
      },
    },
    where: {
      type: Object,
      default() {
        return {};
      },
    },
    isComment: {
      type: Boolean,
      default() {
        return true;
      },
    },
    topicId: {
      type: String,
      default() {
        return "0";
      },
    },
  },
  data() {
    return {
      replyForm: { content: "" },
      publishForm: { content: "" },
      dataList: {
        page: 1,
        pageSize: 10,
        total: 0,
        list: [],
      },
      loading: false,
      // typeList: {
      //   course: {
      //     submit: "api/v1_0_0.course/comment_submit",
      //     list: "api/v1_0_0.course/comment_list",
      //   },
      //   question: {
      //     submit: "api/v1_0_0.question/comment_submit",
      //     list: "api/v1_0_0.question/comment_list",
      //   },
      //   forum: {
      //     submit: "api/v1_0_0.forum/comment_submit",
      //     list: "api/v1_0_0.forum/comment_list",
      //   },
      // },
    };
  },
  watch: {
    //   "$props.where"() {
    //     this.getList();
    //   },
    topicId() {
      this.getList();
    },
  },
  created() {
    console.log(this.topicId, "传进来的题目id");
    this.getList();
  },
  methods: {
    getList() {
      this.loading = true;
      this.$http
        .post("api/v1_0_0.question/comment_list", {
          id: this.topicId,
          page: this.dataList.page,
          page_size: this.dataList.pageSize,
        })
        .then(({ data }) => {
          this.dataList.list = data.data || [];
          this.dataList.total = data.total || 0;
          console.log("comment getList", this.type, this.dataList);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handleCommandReply(pid) {
      if (!pid) return;
      let { ...data } = this.replyForm;
      if (!data.content && data.content !== 0) {
        return this.$message.error("请输入回复的评价内容");
      }
      this.loading = true;
      return this.$http
        .post("api/v1_0_0.question/comment_submit", {
          id: this.topicId,
          ...data,
          pid,
        })
        .then(() => {
          this.dataList.page = 1;
          this.getList();
        })
        .finally(() => {
          this.replyForm = { content: "" };
          this.$refs["popover" + pid] &&
            this.$refs["popover" + pid][0].doClose();
        });
    },
    handleCommandPublish() {
      let { ...data } = this.publishForm;
      if (!data.content && data.content !== 0) {
        return this.$message.error("请输入评价内容");
      }
      this.loading = true;
      return this.$http
        .post("api/v1_0_0.question/comment_submit", {
          id: this.topicId,

          ...data,
        })
        .then(() => {
          this.dataList.page = 1;
          this.getList();
          this.loading = false;
        })
        .finally(() => {
          this.loading = false;

          this.publishForm = { content: "" };
        });
    },
  },
};
</script>
  
  <style scoped lang="less">
::v-deep {
  .el-input__suffix {
    right: 0;
  }
  // .el-input-group__append {
  // }
  .evaluate-item {
    margin-bottom: 1rem;
    display: flex;
    align-items: center;

    .text {
      flex: 8;
      // width: 100%;
      margin-left: 1rem;
      background: #ffffff;
      border: 1px solid #979797;
      padding: 1rem;
      font-size: 14px;
      .text-user {
        color: #525252;
        margin-bottom: 0.5rem;
      }
    }
    .user {
      text-align: center;
      font-size: 10px;
      width: 90px;
    }
    .time {
      text-align: right;
      color: #525252;
      font-size: 12px;
      margin-top: 0.5rem;
      .buttom {
        .el-button {
          padding: 0;
        }
      }
      .reply {
        margin-left: 0.8rem;
        cursor: pointer;
        .el-popover__reference-wrapper,
        .el-popover__reference-wrapper * {
          display: inline-block;
        }
        .el-button--text {
          padding: 0;
        }
      }
    }
  }
}
</style>